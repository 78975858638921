import React from 'react'
import { makeStyles } from '@material-ui/styles'
import SbEditable from 'storyblok-react'
import { Box } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stacked: {
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  iconContainer: {
    height: '70px',
    width: '70px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '1rem',
  },
  icon: {
    height: '60px',
  },
  highlight: {
    width: '10rem',
    fontWeight: 700,
    fontSize: '0.85rem',
  },
}))

const IconHighlight = (props) => {
  const { icon, highlight, stacked } = props.blok
  const classes = useStyles(props)

  return (
    <SbEditable content={props.blok}>
      <Box className={classNames(classes.root, { [classes.stacked]: stacked })}>
        <Box className={classes.iconContainer}>
          {!!icon && (
            <img alt="icon" src={icon.filename} className={classes.icon} />
          )}
        </Box>
        <Box className={classes.highlight}>{highlight}</Box>
      </Box>
    </SbEditable>
  )
}

export default IconHighlight
